<template>
  <!-- 过户详情 -->
  <div class="transferOwnershipDetails page-info-content">
    <DetailsPage :detail-obj="inventoryDetailObj" :details-item-arr="detailsItemArr" />
    <h3 class="page-subtitle-shade">
      货位明细
    </h3>
    <Table :item-data="itemData" :list-data="inventoryDetailObj.wmsInventoryTransferdetailRespList" :loading="loading">
      <template #srcInvWeight="{ row }">
        <span>{{ row.srcInvWeight }}{{ row.unit }}</span>
      </template>
    </Table>
    <ProductNamePopUpLayer :id="pnid" ref="ProductNamePopUpLayer" />
  </div>
</template>

<script>
import { timestampToTime } from '../../../utils/util'
import ProductNamePopUpLayer from '../components/ProductNamePopUpLayer'
import Table from '@/components/Table'
import DetailsPage from '@/components/DetailsPage'
import { inventoryTransferDetails } from '../../../api/warehousingSystem'
export default {
  components: { ProductNamePopUpLayer, DetailsPage, Table },
  data() {
    return {
      loading: false,
      pnid: '',
      inventoryDetailObj: {},
      detailsItemArr: [
        { label: '过户单号', value: 'transferNo' },
        { label: '仓库名称', value: 'warehouseName' },
        { label: '原货主名称', value: 'oldCustName' },
        { label: '新货主名称', value: 'custName' },
        { label: '过户日期', value: 'transferDate' },
        { label: '操作人', value: 'operator' }
      ],
      itemData: [
        { label: '品名', prop: 'goodsName' },
        { label: '材质', prop: 'materials' },
        { label: '规格', prop: 'specs' },
        { label: '产地', prop: 'productions' },
        { label: '件重(吨)', prop: 'weights' },
        { label: '库存数量/单位', prop: 'srcInvWeight' },
        { label: '库区编号', prop: 'goodsYardNo' },
        { label: '货位号', prop: 'detailLocationNo' },
        { label: '过户数量', prop: 'invWeight' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/warehousingSystem/transferOwnership', name: '过户管理' }, { path: '', name: '过户详情' }]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      inventoryTransferDetails({ id: this.$route.query.id }, res => {
        this.inventoryDetailObj = { ...res.data }
        this.inventoryDetailObj.wmsInventoryTransferdetailRespList = this.inventoryDetailObj.wmsInventoryTransferdetailRespList.map((item) => ({
          goodsName: res.data.goodsName,
          materials: res.data.materials,
          specs: res.data.specs,
          productions: res.data.productions,
          weights: res.data.weights,
          ...item
        }))
        this.pnid = this.inventoryDetailObj.goodsWeightsId
        this.inventoryDetailObj.modifyTime = timestampToTime(this.inventoryDetailObj.modifyTime)
      })
    }
  },
  methods: {
    open() {
      this.$refs.ProductNamePopUpLayer.dialogTableVisible = true
    }
  }
}
</script>

<style>
.transferOwnershipDetails .el-descriptions {
  padding: 40px 32px 0;
}
.transferOwnershipDetails .el-descriptions:last-of-type {
  padding: 0 32px;
}
.transferOwnershipDetails .el-descriptions-row td {
  padding-bottom: 28px !important;
}
.transferOwnershipDetails .tableBox {
  margin: auto;
  text-align: center;
  width: calc(100% - 128px);
  border-collapse: collapse;
}
.transferOwnershipDetails .tableBox td {
  height: 40px;
  border: 1px solid #ededed;
}
.transferOwnershipDetails .el-descriptions .el-descriptions__body .link {
  color: rgb(64, 158, 255);
  text-decoration: underline;
  cursor: pointer;
}
.transferOwnershipDetails .el-descriptions .el-descriptions__body {
  padding: 0 32px;
}
</style>
